import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import Sidebar from './Sidebar';
import { updateUserSettings } from '../api/wordpress';
import { useAuth } from '../context/AuthContext';
import { ArrowRightOnRectangleIcon, QuestionMarkCircleIcon, XMarkIcon, ArrowPathIcon } from '@heroicons/react/24/outline';

function Settings() {
  const { user, logout, isLoading, checkLoginStatus, userUsage, userPlan, fetchUserUsageAndPlan } = useAuth();
  const [searchParams] = useSearchParams();
  const [localUser, setLocalUser] = useState({
    name: '',
  });
  const [password, setPassword] = useState({
    current: '',
    new: '',
    confirm: '',
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isUsageStatsModalOpen, setIsUsageStatsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('credits_balance');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
    
  useEffect(() => {
    if (location.state && location.state.usageLimitExceeded) {
      const creditDetails = location.state.creditDetails || {};
      const { subscriptionRemaining, paygCredits } = creditDetails;
      
      let errorMessage = 'You need at least 1 credit to create a design. ';
      
      if (subscriptionRemaining === 0 && paygCredits === 0) {
        errorMessage += 'You have no credits remaining. Please upgrade your plan or purchase PAYG credits to continue creating designs.';
      } else if (subscriptionRemaining === 0) {
        errorMessage += `You have used all your subscription credits for this month, but you have ${paygCredits} PAYG credits available.`;
      } else {
        errorMessage += `You have ${subscriptionRemaining} subscription credits and ${paygCredits} PAYG credits available.`;
      }
      
      setError(errorMessage);
      setActiveTab('credits_balance');
    }
  }, [location]);

  const openPricing = () => {
    window.open('http://dezygn-20.local/pricing/', '_blank');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalUser({ ...localUser, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUserSettings(localUser);
      setSuccessMessage('User information updated successfully');
      await checkLoginStatus();
    } catch (err) {
      console.error('Error updating user information:', err);
      setError('Failed to update user information');
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (password.new !== password.confirm) {
      setError('New passwords do not match');
      return;
    }
    try {
      await updateUserSettings({ password: password.new });
      setSuccessMessage('Password updated successfully');
      setPassword({ current: '', new: '', confirm: '' });
    } catch (err) {
      setError('Failed to update password');
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleRefreshBalance = async () => {
    setIsRefreshing(true);
    try {
      await fetchUserUsageAndPlan();
      setSuccessMessage('Credits balance refreshed');
    } catch (err) {
      setError('Failed to refresh credits balance');
    } finally {
      setIsRefreshing(false);
      setTimeout(() => setSuccessMessage(''), 3000);
    }
  };

  const openUsageStatsModal = () => {
    setIsUsageStatsModalOpen(true);
  };

  const closeUsageStatsModal = () => {
    setIsUsageStatsModalOpen(false);
  };

  const getDaysUntilEndOfMonth = () => {
    const now = new Date();
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const daysRemaining = lastDayOfMonth.getDate() - now.getDate() + 1;
    return daysRemaining;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case 'credits_balance':
        return (
          <div className="space-y-6">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100">Credits Balance</h3>
                <button
                  onClick={handleRefreshBalance}
                  className="group relative p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 rounded-full"
                  title="Refresh balance"
                >
                  <ArrowPathIcon 
                    className={`h-5 w-5 ${isRefreshing ? 'animate-spin' : ''}`} 
                  />
                  <span className="sr-only">Refresh balance</span>
                  <span className="absolute hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2 -bottom-8 left-1/2 transform -translate-x-1/2">
                    Refresh balance
                  </span>
                </button>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-blue-50 dark:bg-blue-900 p-4 rounded-lg">
                  <h4 className="text-lg font-medium text-blue-700 dark:text-blue-300 mb-2">Subscription Credits</h4>
                  {userPlan ? (
                    <div>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Plan: {userPlan.name} ({userPlan.tokens_per_month} credits / month)
                      </p>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Credits used: {userPlan.subscription_credits_used} / {userPlan.tokens_per_month}
                      </p>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Credits left: {userPlan.tokens_per_month - userPlan.subscription_credits_used}
                      </p>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Credits reset in: {getDaysUntilEndOfMonth()} days
                      </p>
                      <button
                        onClick={openPricing}
                        className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Change Subscription
                      </button>
                    </div>
                  ) : (
                    <p className="text-sm text-gray-600 dark:text-gray-400">Loading subscription stats...</p>
                  )}
                </div>
                <div className="bg-green-50 dark:bg-green-900 p-4 rounded-lg">
                  <h4 className="text-lg font-medium text-green-700 dark:text-green-300 mb-2">Pay-as-you-go Credits</h4>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    Available credits: {userPlan ? userPlan.payg_credits : 0}
                  </p>
                  <button
                    onClick={openPricing}
                    className="mt-4 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    Buy More Credits
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case 'profile':
        return (
          <div className="space-y-8 divide-y divide-gray-200 dark:divide-gray-600">
            <form onSubmit={handleSubmit}>
              <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Personal Information</h3>
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">View your username below.</p>
              <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Username</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={localUser.name}
                    readOnly
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100 dark:bg-gray-600 dark:text-gray-200 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </form>
            <form onSubmit={handlePasswordSubmit} className="pt-8">
              <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Change Password</h3>
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">Update your password associated with your account.</p>
              <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div className="sm:col-span-2">
                  <label htmlFor="current_password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Current password</label>
                  <input
                    type="password"
                    name="current"
                    id="current_password"
                    value={password.current}
                    onChange={handlePasswordChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-600 dark:text-gray-200 dark:border-gray-500"
                  />
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="new_password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">New password</label>
                  <input
                    type="password"
                    name="new"
                    id="new_password"
                    value={password.new}
                    onChange={handlePasswordChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-600 dark:text-gray-200 dark:border-gray-500"
                  />
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="confirm_password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Confirm password</label>
                  <input
                    type="password"
                    name="confirm"
                    id="confirm_password"
                    value={password.confirm}
                    onChange={handlePasswordChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-600 dark:text-gray-200 dark:border-gray-500"
                  />
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 flex items-center">Update Password</button>
              </div>
            </form>
            <div className="pt-8">
              <button
                className="w-full py-2 px-4 border border-red-300 rounded-md shadow-sm text-sm font-medium text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 dark:bg-gray-600 dark:text-red-400 dark:border-red-500 dark:hover:bg-red-900 flex items-center justify-center"
              >
                Delete account
              </button>
            </div>
          </div>
        );
      case 'billing':
        return (
          <div className="space-y-6">
            <div className="bg-white dark:bg-gray-700 rounded-lg shadow-md p-4">
              <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-4">Payment history</h3>
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                <thead className="bg-gray-50 dark:bg-gray-800">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Number</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Date</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">Download</th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-700 divide-y divide-gray-200 dark:divide-gray-600">
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400" colSpan="3">No payment history available</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="space-y-2">
              <a href="#" onClick={(e) => e.preventDefault()} className="block text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200">
                Update payment method
              </a>
              <a href="#" onClick={(e) => e.preventDefault()} className="block text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200">
                Add invoice info
              </a>
              <a href="#" onClick={(e) => e.preventDefault()} className="block text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-200">
                Cancel subscription
              </a>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 dark:bg-gray-900">
          <div className="container mx-auto px-4 sm:px-6 py-4 h-full">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
              <h3 className="text-gray-700 dark:text-gray-200 text-2xl font-medium mb-2 sm:mb-0">Settings</h3>
            </div>
            {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">{error}</div>}
            {successMessage && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">{successMessage}</div>}
            <div className="flex flex-col lg:flex-row lg:space-x-6 space-y-6 lg:space-y-0 h-[calc(100vh-200px)]">
              <div className="w-full lg:w-1/4 bg-white dark:bg-gray-700 rounded-lg shadow-md p-4 flex flex-col h-full">
                <nav className="space-y-1 flex-grow" aria-label="Sidebar">
                  {[
                    { id: 'credits_balance', label: 'Credits Balance' },
                    { id: 'profile', label: 'Profile' },
                    { id: 'billing', label: 'Billing' }
                  ].map((tab) => (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={`${
                        activeTab === tab.id
                          ? 'bg-gray-100 text-gray-900 dark:bg-gray-600 dark:text-white'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white'
                      } w-full flex items-center px-3 py-2 text-sm font-medium rounded-md`}
                    >
                      {tab.label}
                    </button>
                  ))}
                </nav>
                <div className="mt-auto pt-4 border-t border-gray-200 dark:border-gray-600">
                  <button
                    onClick={handleLogout}
                    className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-gray-600 dark:text-gray-200 dark:border-gray-500 dark:hover:bg-gray-700 flex items-center justify-center"
                  >
                    <ArrowRightOnRectangleIcon className="h-5 w-5 mr-2" />
                    Log out
                  </button>
                </div>
              </div>
              <div className="w-full lg:w-3/4 bg-white dark:bg-gray-700 rounded-lg shadow-md p-4 overflow-y-auto h-full">
                {renderTabContent()}
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* Usage Stats Modal */}
      {isUsageStatsModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Credit Usage Information</h3>
              <button onClick={closeUsageStatsModal} className="text-gray-400 hover:text-gray-500">
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="space-y-4 text-sm text-gray-600 dark:text-gray-400">
              <p>🖼️ Generate 1 image: 1 credit</p>
              <p>🎭 Remove a background: 4 credits (coming soon)</p>
              <p>🔍 Upscale an image: 2 credits (coming soon)</p>
              <p>😊 Fix a face: 2 credits (coming soon)</p>
              <p>✏️ Remove text: 2 credits (coming soon)</p>
              <p>🧠 Train a character: 20 credits (coming soon)</p>
              <div className="border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
                <p className="font-medium mb-2">About your credit allowance:</p>
                <p>During the Dezygn V2 Beta, all plans will be capped at 500 images a month to ensure server stability. After the beta, your credits will be adjusted to match your current Dezygn V1 plan.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Settings;
