import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PhotoIcon, SwatchIcon, ClockIcon, WrenchIcon } from '@heroicons/react/24/outline';

function Sidebar() {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? 'bg-gray-700' : '';
  };

  return (
    <div className="flex md:flex-shrink-0 transition-all duration-300 ease-in-out w-16">
      <div className="flex flex-col w-full h-screen">
        <div className="flex flex-col flex-grow bg-gray-900">
          <div className="flex items-center justify-center flex-shrink-0 px-4 py-5">
            <img className="h-8 w-auto" src="/logo.png" alt="Logo" />
          </div>
          <nav className="flex-1 px-2 bg-gray-900 space-y-4">
            <Link to="/" className={`group flex flex-col items-center px-2 py-2 text-[10px] font-medium text-white rounded-md hover:bg-gray-700 transition ease-in-out duration-150 ${isActive('/')}`}>
              <PhotoIcon className="h-6 w-6 mb-1" />
              <span>Designs</span>
            </Link>
            <Link to="/brands" className={`group flex flex-col items-center px-2 py-2 text-[10px] font-medium text-white rounded-md hover:bg-gray-700 transition ease-in-out duration-150 ${isActive('/brands')}`}>
              <SwatchIcon className="h-6 w-6 mb-1" />
              <span>Brands</span>
            </Link>
            <Link to="/history" className={`group flex flex-col items-center px-2 py-2 text-[10px] font-medium text-white rounded-md hover:bg-gray-700 transition ease-in-out duration-150 ${isActive('/history')}`}>
              <ClockIcon className="h-6 w-6 mb-1" />
              <span>History</span>
            </Link>
          </nav>
        </div>
        <div className="flex-shrink-0 flex items-center justify-center p-4 bg-gray-900">
          <Link to="/settings" className={`group flex flex-col items-center px-2 py-2 text-[10px] font-medium text-white rounded-md hover:bg-gray-700 transition ease-in-out duration-150 ${isActive('/settings')}`}>
            <WrenchIcon className="h-6 w-6 mb-1" />
            <span>Settings</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;