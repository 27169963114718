import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { getUserUsageStats, getUserPlanDetails } from '../api/wordpress';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [userUsage, setUserUsage] = useState(null);
  const [userPlan, setUserPlan] = useState(null);

  const WP_API_URL = process.env.REACT_APP_WP_API_URL;

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const checkLoginStatus = async () => {
    const token = localStorage.getItem('jwt_token');
    if (token) {
      try {
        const response = await axios.get(`${WP_API_URL}/wp/v2/users/me`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUser(response.data);
        setIsLoggedIn(true);
        await fetchUserUsageAndPlan();
      } catch (error) {
        console.error('Error checking login status:', error);
        if (error.response && error.response.status === 401) {
          // Token is expired or invalid, try to refresh
          const refreshed = await refreshToken();
          if (!refreshed) {
            logout();
          }
        } else {
          logout();
        }
      }
    } else {
      logout();
    }
    setIsLoading(false);
  };

  const fetchUserUsageAndPlan = async () => {
    try {
      const [usageStats, planDetails] = await Promise.all([
        getUserUsageStats(),
        getUserPlanDetails()
      ]);
      setUserUsage(usageStats);
      setUserPlan(planDetails);
    } catch (error) {
      console.error('Error fetching user usage and plan:', error);
    }
  };

  const refreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        return false;
      }
      const response = await axios.post(`${WP_API_URL}/jwt-auth/v1/token/refresh`, {
        refresh_token: refreshToken
      });
      localStorage.setItem('jwt_token', response.data.token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      setIsLoggedIn(true);
      await fetchUserUsageAndPlan();
      return true;
    } catch (error) {
      console.error('Token refresh error:', error);
      return false;
    }
  };

  const login = async (username, password) => {
    try {
      const response = await axios.post(`${WP_API_URL}/jwt-auth/v1/token`, {
        username,
        password
      });
      localStorage.setItem('jwt_token', response.data.token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      setIsLoggedIn(true);
      await checkLoginStatus(); // Fetch user data after successful login
      return true;
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('refresh_token');
    setIsLoggedIn(false);
    setUser(null);
    setUserUsage(null);
    setUserPlan(null);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, isLoading, user, userUsage, userPlan, login, logout, checkLoginStatus, refreshToken, fetchUserUsageAndPlan }}>
      {children}
    </AuthContext.Provider>
  );
};
