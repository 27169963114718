import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function ProtectedRoute({ children }) {
  const { isLoggedIn, isLoading, userUsage, userPlan } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  // Only check credits for /new-design route
  if (userPlan && location.pathname === '/new-design') {
    // Get subscription credits info
    const subscriptionTotal = parseInt(userPlan.tokens_per_month) || 0;
    const subscriptionUsed = parseInt(userPlan.subscription_credits_used) || 0;
    const subscriptionRemaining = Math.max(0, subscriptionTotal - subscriptionUsed);

    // Get PAYG credits
    const paygCredits = parseInt(userPlan.payg_credits) || 0;

    // Calculate total available credits
    const totalAvailableCredits = subscriptionRemaining + paygCredits;

    console.log('Credit Check:', {
      subscriptionTotal,
      subscriptionUsed,
      subscriptionRemaining,
      paygCredits,
      totalAvailableCredits
    });

    // If no credits available, redirect to settings
    if (totalAvailableCredits <= 0) {
      return <Navigate 
        to="/settings" 
        state={{ 
          from: location, 
          usageLimitExceeded: true,
          creditDetails: {
            subscriptionRemaining,
            paygCredits,
            totalAvailable: totalAvailableCredits
          }
        }} 
      />;
    }
  }

  return children;
}

export default ProtectedRoute;
