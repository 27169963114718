import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { getBrands, createSavedDesign, createGeneration } from '../../api/wordpress';
import axios from 'axios';
import Sidebar from '../Sidebar';
import ImageGrid from './ImageGrid';
import ImageModal from './ImageModal';
import ControlPanel from './ControlPanel';
import Notification from './Notification';
import AdvancedSettings from './AdvancedSettings';
import AssistantTab from './AssistantTab';

const WP_API_URL = process.env.REACT_APP_WP_API_URL;

if (!WP_API_URL) {
  console.error('REACT_APP_WP_API_URL is not set in the environment variables.');
}

axios.defaults.withCredentials = true;

const authAxios = axios.create({
  withCredentials: true
});

authAxios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('jwt_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

authAxios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('jwt_token');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

const NewDesign = () => {
  const { isLoggedIn, isLoading, userPlan } = useAuth();  // Added userPlan
  const navigate = useNavigate();
  const [prompt, setPrompt] = useState('');
  const [brand, setBrand] = useState('');
  const [brands, setBrands] = useState([]);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [imageSize, setImageSize] = useState('landscape_4_3');
  const [numImages, setNumImages] = useState(4);
  const [modalImage, setModalImage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [activeTab, setActiveTab] = useState('create');
  const [generationData, setGenerationData] = useState(null);
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      fetchBrands();
    }
  }, [isLoggedIn]);

  const fetchBrands = async () => {
    try {
      const fetchedBrands = await getBrands();
      setBrands(fetchedBrands);
    } catch (err) {
      console.error('Error fetching brands:', err);
    }
  };

  const showNotification = (message, type = 'success') => {
    setNotification({ message, type });
    setTimeout(() => setNotification({ message: '', type: '' }), 5000); // Increased timeout for error messages
  };

  const handleCreateDesign = async () => {
    if (!isLoggedIn) {
      showNotification('Please log in to create a design.', 'error');
      return;
    }

    if (!WP_API_URL) {
      showNotification('The WordPress API URL is not set. Please check your environment configuration.', 'error');
      return;
    }

    if (!prompt.trim()) {
      showNotification('Please enter a prompt before creating a design.', 'error');
      return;
    }

    // Add credit check here
    if (userPlan) {
      const totalAvailable = (userPlan.tokens_per_month - userPlan.subscription_credits_used) + 
                            parseFloat(userPlan.payg_credits);
      
      if (totalAvailable < numImages) {
        showNotification(
          `You have exceeded your usage limit. You need ${numImages} credits but only have ${totalAvailable} available.`, 
          'error'
        );
        return;
      }
    }

    setIsGenerating(true);
    try {
      const selectedBrand = brands.find(b => b.title === brand);
      const fullPrompt = selectedBrand
        ? `${prompt} ${selectedBrand.style_suffix}`
        : prompt;

      // Calculate tokens - 1 token per image
      const totalTokens = numImages;

      console.log('Token calculation:', {
        totalTokens,
        numImages
      });

      const fluxResponse = await axios.post(
        'https://fal.run/fal-ai/flux/dev',
        {
          prompt: fullPrompt,
          image_size: imageSize,
          num_images: numImages,
        },
        {
          headers: {
            'Authorization': 'Key 87e7b6bd-c706-405e-889e-b0d6d5f584fd:001b9fde8e4a972fc56c4062c4143c67',
            'Content-Type': 'application/json'
          }
        }
      );

      const imageUrls = fluxResponse.data.images.map(img => img.url);
      setGeneratedImages(imageUrls);
      setGenerationData(fluxResponse.data);

      console.log('Generation created:', fluxResponse.data);

      // Save the generation to WordPress with calculated tokens
      const generationData = {  // Fixed variable name from generationfData to generationData
        title: prompt,
        status: 'publish',
        content: prompt,
        fields: {
          design_prompt: fullPrompt,
          image_size: imageSize,
          num_images: numImages,
          image_1_url: imageUrls[0] || '',
          image_2_url: imageUrls[1] || '',
          image_3_url: imageUrls[2] || '',
          image_4_url: imageUrls[3] || '',
          api_response: JSON.stringify(fluxResponse.data),
          tokens_used: totalTokens
        }
      };

      console.log('Sending generation data to WordPress:', generationData); // Fixed to log the correct variable

      const wpResponse = await createGeneration(generationData); // Fixed to pass the correct variable
      console.log('Generation saved to WordPress:', wpResponse);

      showNotification('💪 Visuals created!');

    } catch (error) {
      console.error('Error creating generation:', error);
      
      // Handle credit-related errors specifically
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.data.message.includes('exceeded your usage limit') || 
            error.response.data.message.includes('Insufficient credits')) {
          showNotification(error.response.data.message, 'error');
        } else if (error.response.status === 401) {
          showNotification('Authentication error. Please log in again.', 'error');
        } else {
          showNotification(`Error: ${error.response.data.message}`, 'error');
        }
      } else if (error.request) {
        showNotification('Network error. Please check your connection and try again.', 'error');
      } else {
        showNotification('An unexpected error occurred. Please try again.', 'error');
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSave = async (imageUrl) => {
    if (!isLoggedIn) {
      showNotification('Please log in to save a design.', 'error');
      return;
    }

    if (!WP_API_URL) {
      showNotification('The WordPress API URL is not set. Please check your environment configuration.', 'error');
      return;
    }

    setIsSaving(true);
    try {
      // Calculate tokens - 1 token per image
      const totalTokens = numImages;

      const response = await createSavedDesign({
        title: prompt,
        status: 'publish',
        content: prompt,
        fields: {
          saved_design_prompt: prompt,
          saved_image_url: imageUrl,
          design_data: JSON.stringify(generationData),
          api_response: JSON.stringify(generationData),
          image_1_url: generatedImages[0] || '',
          image_2_url: generatedImages[1] || '',
          image_3_url: generatedImages[2] || '',
          image_4_url: generatedImages[3] || '',
          tokens_used: totalTokens
        }
      });
      console.log('Design saved:', response);
      showNotification('Saved! 🤗');
    } catch (error) {
      console.error('Error saving design:', error);
      if (error.response && error.response.data && error.response.data.message) {
        showNotification(`Error: ${error.response.data.message}`, 'error');
      } else if (error.request) {
        showNotification('Network error. Please check your connection and try again.', 'error');
      } else {
        showNotification('An unexpected error occurred while saving. Please try again.', 'error');
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleDownload = (imageUrl) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = `design-${Date.now()}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCopyBrand = () => {
    const selectedBrand = brands.find(b => b.title === brand);
    if (selectedBrand) {
      navigator.clipboard.writeText(selectedBrand.style_suffix)
        .then(() => showNotification('Brand brief copied to clipboard!'))
        .catch(err => console.error('Failed to copy brand brief:', err));
    }
  };

  if (isLoading) {
    return <div className="dark:text-gray-200">Loading...</div>;
  }

  if (!isLoggedIn) {
    navigate('/login');
    return null;
  }

  return (
    <div className="flex flex-col h-screen bg-gray-100 dark:bg-gray-900 lg:flex-row">
      <Sidebar />
      <div className="flex-1 flex flex-col lg:flex-row overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white dark:bg-gray-800">
          <div className="h-full p-2 sm:p-4">
            <ImageGrid 
              images={generatedImages} 
              onImageClick={(imageUrl, index) => {
                setModalImage(imageUrl);
                setCurrentImageIndex(index);
              }} 
            />
          </div>
        </main>
        <aside className={`w-full lg:w-80 bg-gray-50 dark:bg-gray-800 p-4 border-t lg:border-l border-gray-200 dark:border-gray-700 overflow-hidden flex flex-col ${isSidebarOpen ? 'block' : 'hidden lg:block'}`}>
          <button
            className="lg:hidden mb-4 text-gray-600 dark:text-gray-300"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            {isSidebarOpen ? 'Close Sidebar' : 'Open Sidebar'}
          </button>
          <ControlPanel
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            brand={brand}
            setBrand={setBrand}
            brands={brands}
            handleCopyBrand={handleCopyBrand}
            prompt={prompt}
            setPrompt={setPrompt}
            imageSize={imageSize}
            setImageSize={setImageSize}
            numImages={numImages}
            setNumImages={setNumImages}
            handleCreateDesign={handleCreateDesign}
            isGenerating={isGenerating}
            isAdvancedSettingsOpen={isAdvancedSettingsOpen}
            setIsAdvancedSettingsOpen={setIsAdvancedSettingsOpen}
          >
            {activeTab === 'create' && isAdvancedSettingsOpen && (
              <AdvancedSettings
                imageSize={imageSize}
                setImageSize={setImageSize}
                numImages={numImages}
                setNumImages={setNumImages}
              />
            )}
            {activeTab === 'assistant' && <AssistantTab />}
          </ControlPanel>
        </aside>
      </div>

      {modalImage && (
        <ImageModal
          image={modalImage}
          onClose={() => setModalImage(null)}
          onSave={handleSave}
          onDownload={handleDownload}
          currentIndex={currentImageIndex}
          totalImages={generatedImages.length}
          onPrev={() => {
            const newIndex = currentImageIndex === 0 ? generatedImages.length - 1 : currentImageIndex - 1;
            setModalImage(generatedImages[newIndex]);
            setCurrentImageIndex(newIndex);
          }}
          onNext={() => {
            const newIndex = currentImageIndex === generatedImages.length - 1 ? 0 : currentImageIndex + 1;
            setModalImage(generatedImages[newIndex]);
            setCurrentImageIndex(newIndex);
          }}
        />
      )}

      <Notification message={notification.message} type={notification.type} />
    </div>
  );
};

export default NewDesign;
